<template>
  <div>
    <v-row
      no-gutters
      v-for="item in items"
      :key="`items-${item.pk}`"
      class="infocard mb-1 pl-3 py-2"
    >
      <!-- Información del item si es suborden -->
      <v-col v-if="!isDriver" cols="12" sm="6" md="4" class="primary--text">
        <span> {{ item.code }} - {{ item.name }} </span>
      </v-col>

      <!-- Foto del item si es un conductor -->
      <v-list-item-avatar v-if="isDriver" size="60" color="grey darken-3">
        <i-image :value="item.photo" :preview="true" :readonly="true" />
      </v-list-item-avatar>

      <!-- Nombre del conductor o el producto de la suborden -->
      <v-col cols="12" sm="6" md="4">
        {{ isDriver ? `${item.first_name} ${item.last_name}` : item.own_prod }}
      </v-col>

      <!-- Asignación de usuario o mensaje de alerta si no se puede asignar -->
      <v-col cols="12" md="4">
        <assignuser
          v-if="isProducerAssignable(item)"
          :selectedItem="item.pk"
          :mode="mode"
          :template="item.template"
        />
        <v-alert v-else type="info" outlined color="primary">
          <span class="primary--text font-weight-black">
            {{ $t('assignProduction_no') }}
          </span>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import assignuser from './infoAssignItem.vue'

export default {
  components: {
    assignuser
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      default: 'designer'
    }
  },
  computed: {
    isDriver() {
      return this.mode === 'driver'
    }
  },
  methods: {
    /**
     * isProducerAssignable
     * Método para determinar si un productor puede ser asignado a una suborden
     * basada en el modo 'production' y el estado de la orden.
     *
     * @param {Object} item - El objeto de la suborden que contiene información
     * para la asignación.
     * @returns {boolean} - Devuelve verdadero si el productor puede ser
     * asignado, de lo contrario, devuelve falso.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    isProducerAssignable(item) {
      const validModes = ['delivery', 'driver', 'designer']
      const invalidOrderStatuses = [
        'design_under_review - in_wait',
        'design - in_wait',
        'design - in_design'
      ]
      const isValidMode = validModes.includes(this.mode)
      const isProduction = this.mode === 'production'
      const isOrderStatusInvalid = invalidOrderStatuses.includes(
        item.orderStatus
      )
      const isOrderStatusValid =
        item.orderStatus === '' ||
        item.orderStatus === 'design - finished' ||
        item.orderStatus === 'production - in_wait' ||
        item.orderStatus === 'production - finished'
      return (
        isValidMode ||
        (isProduction && !isOrderStatusInvalid && isOrderStatusValid)
      )
    }
  }
}
</script>
